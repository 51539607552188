import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from './Components/Home';
// import Blog from './Components/Blog'; // Assuming you have a Blog component
// import BlogTemplate from './Components/Blog/Pages/BlogTemplate'; // Assuming you have a Blog component





const App = () => {
    return (


		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				{/* <Route path="/blog" element={<Blog />} />
				<Route path="/blog/:postSlug" element={<BlogTemplate />} /> */}
			</Routes>
		</Router>


    );
};

export default App;


