// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnm3OHtJUNLYOP6bG7h_ {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
	/* background-color: green; */
}

.OoTXB8FeDwvo3EjQJjfu {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background: pink; */
    color: black;
    padding: 3rem;
}

.kycaXm77XF9LZIUm6A0Z {
    max-width: 200px;
    margin: 2rem auto 1.5rem;
}

.K7tNGLIf19Gd48CumGi7 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
	text-align: center;
}

.EYVvAseOv3AlePltBiJ8 {
    font-size: 1.25rem;
    color: #333333;
}


.xz8pkqJrKSE0sCSREbEA {
    font-size: 0.9rem;
    color: red;
	font-style: italic;
}

.UNfR572njA6YCkNGQUKA {
	text-align: center;
	margin-top: 4rem;
	color: black;
}



@media (max-width: 768px) {
    .K7tNGLIf19Gd48CumGi7 {
        font-size: 2.5rem;
    }
    .EYVvAseOv3AlePltBiJ8 {
        font-size: 1rem;
    }

}

`, "",{"version":3,"sources":["webpack://./code/Components/styles/Home.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;CACzB,iBAAiB;CACjB,cAAc;CACd,6BAA6B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;CACxB,kBAAkB;AACnB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;;AAGA;IACI,iBAAiB;IACjB,UAAU;CACb,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;CAChB,YAAY;AACb;;;;AAIA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".container {\n    min-height: 70vh;\n    display: flex;\n    flex-direction: column;\n\tmax-width: 1200px;\n\tmargin: 0 auto;\n\t/* background-color: green; */\n}\n\n.hero {\n    min-height: 50vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    /* background: pink; */\n    color: black;\n    padding: 3rem;\n}\n\n.logo {\n    max-width: 200px;\n    margin: 2rem auto 1.5rem;\n}\n\n.title {\n    font-size: 3.5rem;\n    margin-bottom: 1.5rem;\n\ttext-align: center;\n}\n\n.subtitle {\n    font-size: 1.25rem;\n    color: #333333;\n}\n\n\n.note {\n    font-size: 0.9rem;\n    color: red;\n\tfont-style: italic;\n}\n\n.featureTitle {\n\ttext-align: center;\n\tmargin-top: 4rem;\n\tcolor: black;\n}\n\n\n\n@media (max-width: 768px) {\n    .title {\n        font-size: 2.5rem;\n    }\n    .subtitle {\n        font-size: 1rem;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rnm3OHtJUNLYOP6bG7h_`,
	"hero": `OoTXB8FeDwvo3EjQJjfu`,
	"logo": `kycaXm77XF9LZIUm6A0Z`,
	"title": `K7tNGLIf19Gd48CumGi7`,
	"subtitle": `EYVvAseOv3AlePltBiJ8`,
	"note": `xz8pkqJrKSE0sCSREbEA`,
	"featureTitle": `UNfR572njA6YCkNGQUKA`
};
export default ___CSS_LOADER_EXPORT___;
