


import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import "./styles/styles.css";
import "./styles/buttons.css";
import "./styles/colors.css";
import "./styles/fonts.css";
import "./styles/inputs.css";

const rootElement = document.getElementById('root');
createRoot(rootElement).render(<App />);

