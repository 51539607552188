import React, { useState } from "react";
import styles from "./styles/Contact.module.css";

const Contact = () => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [success, setSuccess] = useState(false);
	const [honeypot, setHoneypot] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
		if (honeypot) return;
        console.log("\t.. formdata:", formData);
        try {
            const response = await fetch("/contact", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setSuccess(true);
                setFormData({ name: "", email: "", message: "" });
				setHoneypot("");
                setTimeout(() => setSuccess(false), 3000);
            }
        } catch (err) {
            console.log("\txx error:", err);
        }
    };

    return (
        <div className={styles.contactContainer}>
            <img
                src="/images/contact-image-480w.jpg"
                srcSet="/images/coffee.jpg 960w, /images/contact-image-480w.jpg 480w"
                sizes="(max-width: 768px) 100vw, 480px"
                alt="contact image"
                className={styles.contactImage}
                loading="lazy"
            />
            <div className={styles.contactForm}>
                <h2>Send a Note - Let's Talk</h2>
                <form onSubmit={handleSubmit}>
					<input
                        type="text"
                        name="website"
                        value={honeypot}
                        onChange={(e) => setHoneypot(e.target.value)}
                        style={{ display: "none" }}
                    />
					<input
                        type="text"
                        name="name"
                        placeholder="name"
                        className={styles.inputField}
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="email"
                        className={styles.inputField}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <textarea
                        name="message"
                        placeholder="message"
                        className={styles.textareaField}
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    <button type="submit" className={styles.submitButton}>send message</button>
                    {success && <span className={styles.success}>.. message sent - thanks for reaching out</span>}
                </form>
            </div>
        </div>
    );
};

export default Contact;