import React, { useState } from "react";
import styles from "./styles/CTA.module.css";

const CTA = () => {
    const [showThanks, setShowThanks] = useState(false);
    const [email, setEmail] = useState("");
	const [honeypot, setHoneypot] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
		if (honeypot) return;
        try {
            const response = await fetch("/waitlist", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email })
            });
            if (response.ok) {
                setShowThanks(true);
                setTimeout(() => setShowThanks(false), 3000);
                setEmail("");
				setHoneypot("");
            }
        } catch (err) {
            console.log("Error:", err);
        }
    };



    return (
        <div className={styles.ctaContainer}>
            {showThanks ? (
                <p className={styles.thanksMessage}>Thanks!!  We will reach out soon...</p>
            ) : (
                <form className={styles.ctaForm} onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="website"
                        value={honeypot}
                        onChange={(e) => setHoneypot(e.target.value)}
                        style={{ display: "none" }}
                    /><input
                        type="email"
                        placeholder="enter your email"
                        className={styles.emailInput}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit" className={styles.ctaButton}>sign-up for notification</button>
                </form>
            )}
        </div>
    );
};

export default CTA;