import React, { useState } from "react";
import styles from "./styles/Features.module.css";

const Features = () => {
    const [openCards, setOpenCards] = useState(Array(6).fill(false));

    const toggleCard = (index) => {
        setOpenCards(prev => {
            const newOpenCards = [...prev];
            newOpenCards[index] = !newOpenCards[index];
            return newOpenCards;
        });
    };

	const features = [
        {
            title: "Immediate Connectivity",
            shortDesc: "As soon as you subscribe, your mqtt account is active and ready to receive data.",
            longDesc: "Once your're on board, your MQTT credentials are created and you are free to start connecting.  There is no onboarding process for your device.  If you send data to mqtt.ohioiot.com, it will be stored and graphed on your behalf."
        },
        {
            title: "No-Config Graphs",
            shortDesc: "All received data is stored and graphed over six timeframes.",
            longDesc: "These graphs are truly out-of-the-box available.  If you send numerical data, it will go to a graph.  If you identify that it's one of our supported types, such as temperature, it will go to a temperature graph where you will find all of your temperature data points together.  This setup is not designed to give you everything you'll ever need in visualizations.  It just allows you to see immediately that your devices are talking and what they are reporting."
        },
        {
            title: "No-Code Command Builder",
            shortDesc: "Menu-driven messages can be send to your devices with a click.",
            longDesc: "If you program your device to listen to 'lights/on, you need an elegant way to tell it 'lights/on'.  You can draft your commands inside the OhioIoT app, save them, and then just have a list of buttons to click to make things.  When you get more sophisticated in your programming, you can have your devices return confirmation to get definitive confirmation that the message arrived successfully."
        },
        {
            title: "No-Code Automations",
            shortDesc: "When your data are out of bounds, receive e-mail, SMS, or WhatsApp.",
            longDesc: "Just like the commands, you need an elegant editor to set thresholds and device alerts.  When a temperature rises too high, or a door stays open to long, you can send commands back to your system to get a result, or you can send yourself an alert with either e-mail, SMS, or WhatsApp."
        },
        {
            title: "Device Dashboard",
            shortDesc: "See the live and last state of all of your devices in one place",
            longDesc: "It's hard when you put your devices in the field, and maybe not have any idea what they are up to.  It's a good idea to have them send back info on their condition, like wifi strength, battery levels, etc.  Then it takes time to think about how you're going to organize this information.  With OhioIoT, if you send info to 'state' or 'settings', that info will aggragte in a device tile for each one of your devices."
        },
        {
            title: "Over-The-Air Updates",
            shortDesc: "Complimentary server space to store your firmware for remote devices.",
            longDesc: "This one may not be the first feature you take advantage of.  But eventually, you'll need to.  Remote devices need a cloud-based location from which to pull their firmware updates.  OhioIoT has a server space in your name to store the last ten firmware files you uploaded so that your devices can pull them down."
        }
    ];

    return (
        <div className={styles.featureGrid}>
            {features.map((feature, index) => (
                <div
                    key={index}
                    className={openCards[index] ? styles.featureCard + " " + styles.open : styles.featureCard}
					onClick={() => toggleCard(index)}
                >
                    <h3>{feature.title}</h3>
                    <p>{feature.shortDesc}</p>
                    <div className={styles.caretWrapper}>
                        <span className={openCards[index] ? styles.caret + " " + styles.open : styles.caret} />
                    </div>
                    <div className={styles.longDescription}>{feature.longDesc}</div>
                </div>
            ))}
        </div>
    );
};

export default Features;