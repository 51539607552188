// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

* {
    font-family: Arial, Verdana
}



h1 {
	font-family: 'Amatic SC'; 
	/* font-family: "Bebas Neue", sans-serif; */
	/* font-family: "Shadows Into Light", cursive; */
	/* font-family: "Gloria Hallelujah", cursive; */
	/* font-family: "Big Shoulders Inline", sans-serif; */
	font-family: arial
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.7rem;

}`, "",{"version":3,"sources":["webpack://./code/styles/fonts.css"],"names":[],"mappings":";;AAEA;IACI;AACJ;;;;AAIA;CACC,wBAAwB;CACxB,2CAA2C;CAC3C,gDAAgD;CAChD,+CAA+C;CAC/C,qDAAqD;CACrD;AACD;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,iBAAiB;;AAElB","sourcesContent":["\r\n\r\n* {\r\n    font-family: Arial, Verdana\r\n}\r\n\r\n\r\n\r\nh1 {\r\n\tfont-family: 'Amatic SC'; \r\n\t/* font-family: \"Bebas Neue\", sans-serif; */\r\n\t/* font-family: \"Shadows Into Light\", cursive; */\r\n\t/* font-family: \"Gloria Hallelujah\", cursive; */\r\n\t/* font-family: \"Big Shoulders Inline\", sans-serif; */\r\n\tfont-family: arial\r\n}\r\n\r\nh2 {\r\n\tfont-size: 2rem;\r\n}\r\n\r\nh3 {\r\n\tfont-size: 1.7rem;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
