import React from "react";
import styles from "./styles/Home.module.css";
import CTA from "./CTA";
import Features from "./Features";
import Brand from "./Brand";
import Why from "./Why";

import Contact from "./Contact";

const Home = () => {
    return (
        <div className={styles.container}>
            <section className={styles.hero}>
                <img src="/images/logo.jpg" alt="qiot logo" className={styles.logo} />
                <h1 className={styles.title}>A Different IoT is Coming</h1>
                <h2>Spring 2025</h2>
				<p className={styles.subtitle}>Transform your project into success, not struggle, with this new take on getting connected.</p>
				
                <CTA />
                <p className={styles.note}>beta testing soon - get on the list</p>
            </section>
            <section className={styles.features}>
                <h3 className={styles.featureTitle}>A Feature Set Designed for Small-Scale Builders</h3>
                <Features />
            </section>
			<section className={styles.features}>
                <h3 className={styles.featureTitle}>A Compelling Brand Promise</h3>
                <Brand />
            </section>
			<section className={styles.features}>
                <h3 className={styles.featureTitle}>Why OhioIoT?</h3>
                <Why />
            </section>
			<section className={styles.features}>
                <Contact />
            </section>
        </div>
    );
};

export default Home;