// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.red {
	color: red
}`, "",{"version":3,"sources":["webpack://./code/styles/colors.css"],"names":[],"mappings":";;;AAGA;CACC;AACD","sourcesContent":["\n\n\n.red {\n\tcolor: red\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
