


import React, { useState } from "react";
import styles from "./styles/Features.module.css";

const Features = () => {

	const [openCards, setOpenCards] = useState(Array(3).fill(false));
	
	const toggleCard = (index) => {
		setOpenCards(prev => {
			const newOpenCards = [...prev];
			newOpenCards[index] = !newOpenCards[index];
			return newOpenCards;
		});
	};

	const whyList = [
		{
			title: "Get Started Quickly",
			shortDesc: "Basic code samples that work and teach you how to do things yourself.",
			longDesc: "There are two key features here.  Firstly, there is nothing that needs to be done on the cloud side for your device to register.  When send to 'data', the software will parse what you've sent, store in the database, and put it on a graph.  You don't need to start by going through a configuration to tell the cloud about each individual device.  Secondly, we're sticking with username and password login.  For small scale projects, this is good enough, and a lot easier to understand."
		},
		{
			title: "Learn While You Go",
			shortDesc: "How-to videos will teach and show how it works, not just how to use us.",
			longDesc: "If you are even here, it's because you want to do it yourself.  But until you have your arms around these technologies, it can take years to have everything under control.  We're not going to attach you to a black box and expect you to be happy.  We're going to talk about what's going on under the hood so you can be making progress in your development, even if you're banking on us for now to get going."
		},
		{
			title: "Affordable Pricing",
			shortDesc: "'Buy me a coffee' level pricing to avoid some painful learning experiences.",
			longDesc: "Maintaining an MQTT broker and database does cost money.  But it also takes time to maintain.  We think we're offering a fair price for that service.  But then, everything else you get is just gravy on top.  You have basic messaging services available without any extra charge, you have a server to store your firmware for over-the-air updates, and you have a read made device dashboard that goes a lot way towards monitoring and debugging your project."
		}
	];


    return (
		<div className={styles.featureGrid}>
			{whyList.map((feature, index) => (
				<div
					key={index}
					className={openCards[index] ? styles.featureCard + " " + styles.open : styles.featureCard}
					onClick={() => toggleCard(index)}
				>
					<h3>{feature.title}</h3>
					<p>{feature.shortDesc}</p>
					<div className={styles.caretWrapper}>
						<span className={openCards[index] ? styles.caret + " " + styles.open : styles.caret} />
					</div>
					<div className={styles.longDescription}>{feature.longDesc}</div>
				</div>
			))}
		</div>
	);
};

export default Features;