// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lWIs5lGydn23NUQEUZFv {
    height: 60px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.ZsqOHLEGpyNbCcer2fsU {
    display: inline-block;
    opacity: 1;
    transition: opacity 1s ease;
}

.ZsqOHLEGpyNbCcer2fsU.snocqJeGsw0jpshvTYFt {
    opacity: 0;
}

.EHNCaoHgNILy356RnWWQ {
    font-size: 1.25rem;
    color: #333;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.EHNCaoHgNILy356RnWWQ.snocqJeGsw0jpshvTYFt {
    opacity: 0;
}


.iCIQuXdZOqgIpxYWNG0U {
    padding: 0.5rem 0.75rem;
    border: none;
    font-size: 0.9rem;
    background: #f5f5f5;
    margin-right: 0.5rem;
}

.qdnzo3OuTt1YD5wWMZVM {
    padding: 0.5rem 1.5rem;
    background: #999999;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.qdnzo3OuTt1YD5wWMZVM:hover {
    background: #808080;
}

.EHNCaoHgNILy356RnWWQ {
    font-size: 1.25rem;
    color: #333333;
}

@media (max-width: 768px) {
    .ZsqOHLEGpyNbCcer2fsU {
        display: block;
    }
    .iCIQuXdZOqgIpxYWNG0U {
        max-width: 250px;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
    .lWIs5lGydn23NUQEUZFv {
        height: auto;
    }
}`, "",{"version":3,"sources":["webpack://./code/Components/styles/CTA.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;CACf,aAAa;IACV,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,gBAAgB;QAChB,eAAe;QACf,qBAAqB;IACzB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".ctaContainer {\n    height: 60px;\n\tdisplay: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.ctaForm {\n    display: inline-block;\n    opacity: 1;\n    transition: opacity 1s ease;\n}\n\n.ctaForm.hide {\n    opacity: 0;\n}\n\n.thanksMessage {\n    font-size: 1.25rem;\n    color: #333;\n    opacity: 1;\n    transition: opacity 0.3s ease;\n}\n\n.thanksMessage.hide {\n    opacity: 0;\n}\n\n\n.emailInput {\n    padding: 0.5rem 0.75rem;\n    border: none;\n    font-size: 0.9rem;\n    background: #f5f5f5;\n    margin-right: 0.5rem;\n}\n\n.ctaButton {\n    padding: 0.5rem 1.5rem;\n    background: #999999;\n    color: white;\n    border: none;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.ctaButton:hover {\n    background: #808080;\n}\n\n.thanksMessage {\n    font-size: 1.25rem;\n    color: #333333;\n}\n\n@media (max-width: 768px) {\n    .ctaForm {\n        display: block;\n    }\n    .emailInput {\n        max-width: 250px;\n        margin-right: 0;\n        margin-bottom: 0.5rem;\n    }\n    .ctaContainer {\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ctaContainer": `lWIs5lGydn23NUQEUZFv`,
	"ctaForm": `ZsqOHLEGpyNbCcer2fsU`,
	"hide": `snocqJeGsw0jpshvTYFt`,
	"thanksMessage": `EHNCaoHgNILy356RnWWQ`,
	"emailInput": `iCIQuXdZOqgIpxYWNG0U`,
	"ctaButton": `qdnzo3OuTt1YD5wWMZVM`
};
export default ___CSS_LOADER_EXPORT___;
