


import React, { useState } from "react";
import styles from "./styles/Features.module.css";

const Features = () => {

	const [openCards, setOpenCards] = useState(Array(3).fill(false));

    const toggleCard = (index) => {
        setOpenCards(prev => {
            const newOpenCards = [...prev];
            newOpenCards[index] = !newOpenCards[index];
            return newOpenCards;
        });
    };

	const brandPromises = [
        {
            title: "Made for the Maker",
            shortDesc: "This system exists to help the developer avoid the weeks of reading docs.",
            longDesc: "Major platforms needs to be ready for a wide range of use cases.  This means a lot of options need to understand before you can start your project.  We are building the tools that are most well suited to the average developer who wants to learn, wants to connect a few devices remotely, and maybe want to make a system to help their small business.  So, we've made some decisions.  That means we might not be for everybody.  But for a lot of you, there is less to think about."
        },
        {
            title: "No Vendor Lock-In",
            shortDesc: "Everyone hates it.  We won't do it.  But if you want to stay, we won't complain.",
            longDesc: "We know the frustration of learning a system only to find out it doesn't really do what you need, but now you are two invested in it to try something else.  No black-box libraries here.  We'll just give you the code you need to get your stuff to work.  If you learn how to attach to our MQTT broker, it means you know how to attach to MQTT brokers in general.  You can create your own and leave us when you're ready.  It's that it takes a while to get there, so we'll be here in the meantime."
        },
        {
            title: "Private Projects",
            shortDesc: "No insights or meta data are leaving our servers.  We store your data for you only.",
            longDesc: "It's not easy to trust a service with ulterior motives.  Is the pricing based off of the marketing value we can get from user information?  No, and it never will be.  We'll stick to getting paid for providing a service to you, and that's it."
        }
    ];


    return (
        <div className={styles.featureGrid}>
            {brandPromises.map((promise, index) => (
                <div
                    key={index}
                    className={openCards[index] ? styles.featureCard + " " + styles.open : styles.featureCard}
					onClick={() => toggleCard(index)}
                >
                    <h3>{promise.title}</h3>
                    <p>{promise.shortDesc}</p>
                    <div className={styles.caretWrapper}>
                        <span className={openCards[index] ? styles.caret + " " + styles.open : styles.caret} />
                    </div>
                    <div className={styles.longDescription}>{promise.longDesc}</div>
                </div>
            ))}
        </div>
    );
};

export default Features;